<!--
 * @Author: your name
 * @Date: 2020-10-20 11:53:58
 * @LastEditTime: 2020-10-20 18:23:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\views\AuthPages\Recovery.vue
-->
<template>
  <div class="Recovery">
    <div class="titleBox d-flex align-items-center">
      <div class="imgBox1" @click="$router.go(-1)">
        <img src="../../assets/images/login/chevron-left.png" alt="" />
      </div>
      <div class="title">{{ $t("AuthPage.e85@lost_google_authenti") }}</div>
    </div>

    <div class="mt-4">
      <div class="form-group" v-for="(v, i) in questionList" :key="i">
        <label>{{ v.problem }} :</label>
        <input
          type="text"
          class="form-control mb-0"
          placeholder="Question 1:"
          v-model="question[i]"
        />
      </div>
      <div>
        <div class="mt-1 dark-color line-height-2 d-flex justify-content-end">
          {{ $t("AuthPage.9d0@please_contact_our_s") }}
        </div>
        <button
          type="submit"
          class="btn btn-primary float-right mt-3"
          @click="handleClickLogin"
        >
          {{ $t("AuthPage.3ba@submit") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Recovery",
  data: () => ({
    questionList: [],
    // 问题列表
    question: [],
  }),
  created () {
    this.getLoginProblem();
  },
  mounted () { },
  methods: {
    // 获取密保问题
    getLoginProblem () {
      let userLoginInfo = JSON.parse(this.$getlocalStorage("userLoginInfo"));
      const data = {
        email: userLoginInfo.email,
      };
      this.$http.getLoginProblem(data).then((res) => {
        if (res.status == 200) {
          this.questionList = res.data;
        }
      });
    },
    // 问题提交
    handleClickLogin () {
      let userLoginInfo = JSON.parse(this.$getlocalStorage("userLoginInfo"));
      let answer = this.question.toString();
      const data = {
        ...userLoginInfo,
        answer,
      };
      this.$http.problemLogin(data).then((res) => {
        if (res.status == 200) {
          let token = res.data.token;
          let userInfo = res.data.user_info;
          this.$setlocalStorage("token", token);
          this.$setlocalStorage("userInfo", JSON.stringify(userInfo));
          this.$router.push("/");
        } else if (res.status == 1033) {
          // this.$router.push("/auth/locked");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Recovery {
  .titleBox {
    margin-bottom: 83px;
    position: relative;
    .imgBox1 {
      position: absolute;
      left: -10px;
      cursor: pointer;
    }
    .title {
      font-size: 28px;
      padding-left: 20px;
      font-weight: 400;
      color: #3f414d;
    }
  }
}
</style>
